import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('div',{staticClass:"auth-inner"},[_c('XyzTransition',{attrs:{"appear":"","xyz":"fade left-3 delay-6"}},[_c('router-link',{staticClass:"brand-logo d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"me-3",attrs:{"src":_vm.appLogo,"max-height":"30px","max-width":"30px","alt":"logo","contain":""}}),_c(VImg,{staticClass:"app-logo me-3",attrs:{"src":require('@/assets/images/svg/oriens-text.svg'),"max-width":"130px","alt":"logo","contain":"","eager":""}})],1)],1),_c(VRow,{staticClass:"auth-row ma-0"},[_c(VCol,{staticClass:"d-none d-lg-block position-relative overflow-hidden pa-0",attrs:{"lg":"6","xl":"8"}},[_c('XyzTransition',{attrs:{"appear":"","xyz":"fade left-3 delay-3"}},[_c('div',{staticClass:"auth-illustrator-wrapper"},[_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"362","src":require(("@/assets/images/misc/mask-v2-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}}),_c(VImg,{staticClass:"auth-tree",attrs:{"height":"226","width":"300","src":require("@/assets/images/misc/tree.png")}})],1)])],1),_c('XyzTransition',{attrs:{"appear":"","xyz":"fade right-3 delay-10"}},[_c(VCol,{staticClass:"d-flex align-center auth-bg pa-8 pb-0",attrs:{"lg":"6","xl":"4"}},[_c(VRow,[_c(VCol,{staticClass:"mx-auto",attrs:{"cols":"12","sm":"8","md":"6","lg":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VTabsItems,{staticClass:"pa-0 ma-0",model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c(VTabItem,[_c('register-one',{on:{"next":_vm.submit},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }