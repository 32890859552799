<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <XyzTransition
        appear
        xyz="fade left-3 delay-6"
      >
        <router-link
          to="/"
          class="brand-logo d-flex align-center"
        >
          <v-img
            :src="appLogo"
            max-height="30px"
            max-width="30px"
            alt="logo"
            contain
            class="me-3"
          />

          <v-img
            :src="require('@/assets/images/svg/oriens-text.svg')"
            max-width="130px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          />
        </router-link>
      </XyzTransition>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="6"
          xl="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <XyzTransition
            appear
            xyz="fade left-3 delay-3"
          >
            <div class="auth-illustrator-wrapper">
              <!-- triangle bg -->
              <img
                height="362"
                class="auth-mask-bg"
                :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              >

              <!-- tree -->
              <v-img
                height="226"
                width="300"
                class="auth-tree"
                src="@/assets/images/misc/tree.png"
              />
            </div>
          </XyzTransition>
        </v-col>

        <XyzTransition
          appear
          xyz="fade right-3 delay-10"
        >
          <v-col
            lg="6"
            xl="4"
            class="d-flex align-center auth-bg pa-8 pb-0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6"
                lg="12"
                class="mx-auto"
              >
                <v-card flat>
                  <v-tabs-items
                    v-model="currentStep"
                    class="pa-0 ma-0"
                  >
                    <v-tab-item>
                      <register-one
                        v-model="formData"
                        @next="submit"
                      />
                    </v-tab-item>
                    <!-- <v-tab-item>
                      <register-two
                        v-model="formData"
                        @prev="currentStep -= 1"
                        @next="submit"
                      />
                    </v-tab-item> -->
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </XyzTransition>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { apolloClient } from '@/vue-apollo'
import { register } from '@/graphql/mutations'
import RegisterOne from './components/RegisterOne.vue'
// import RegisterTwo from './components/RegisterTwo.vue'
import router from '@/router'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    RegisterOne,
    // RegisterTwo,
  },
  setup() {
    const currentStep = ref(0)
    const formData = ref({
      phone: '',
      email: '',
      first_name: '',
      last_name: '',
    })

    const submit = () => {
      store.state.auth.globalLoading = true
      apolloClient.mutate({
        mutation: register,
        variables: formData.value,
      }).then(({ data }) => {
        Vue.notify({
          title: 'Berhasil membuat akun!',
          type: 'success',
          text: 'Selamat datang di OriensCRM.',
        })
        store.state.auth.globalLoading = false
        router.push({ name: 'auth-need-verify', params: { code: data.register.data } })
      }).catch(err => {
        store.state.auth.globalLoading = false
        errorHandling(err)
      })
    }

    return {
      currentStep,
      formData,

      submit,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
